<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 23.25 23.25">
    <path
      :style="iconStyle"
      d="M12,.375A11.625,11.625,0,1,0,23.625,12,11.627,11.627,0,0,0,12,.375Zm0,5.156A1.969,1.969,0,1,1,10.031,7.5,1.969,1.969,0,0,1,12,5.531Zm2.625,11.906a.563.563,0,0,1-.562.563H9.938a.563.563,0,0,1-.562-.562V16.313a.563.563,0,0,1,.563-.562H10.5v-3H9.938a.563.563,0,0,1-.562-.562V11.063a.563.563,0,0,1,.563-.562h3a.563.563,0,0,1,.563.563V15.75h.563a.563.563,0,0,1,.563.563Z"
      transform="translate(-0.375 -0.375)"
    />
  </svg>
</template>

<script>
export default {
  props: {
    height: {
      type: String,
      default: '17.316',
    },
    width: {
      type: String,
      default: '17.316',
    },
    color: {
      type: String,
      default: 'text-color-info',
    },
  },
  computed: {
    /**
     * Icon Style
     * @param {void}
     * @returns {string} - Color
     * @description Assigns the specific color to the icon
     */
    iconStyle: function () {
      return {
        fill: ['$' + this.color, `var(--${this.color})`],
      }
    },
  },
}
</script>
